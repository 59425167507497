<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">SPONSOR转入/转出</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active">
          SPONSOR转入/转出</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">审批状态:</div>
          <el-select
            v-model="auditStatus"
            placeholder="请选择审批状态"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">项目部:</div>
          <el-select
            v-model="projectID"
            placeholder="请选择项目部"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">用工单位:</div>
          <el-select
            v-model="companyID"
            placeholder="请选择用工单位"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">是否急件:</div>
          <el-select
            v-model="urgent"
            placeholder="请选择是否急件"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">护照号:</div>
          <el-input v-model="ppCode" clearable placeholder="请输入护照号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">暂住证号:</div>
          <el-input v-model="iqamaCode" clearable placeholder="暂住证号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">海关入境号:</div>
          <el-input v-model="borderNumber" clearable placeholder="海关入境号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">申请日期:</div>
          <el-date-picker
            v-model="evacuationDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getTransferSponsorApplications2(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add" @click="newBuilt">
          <i class="fa ion-plus-round"></i>新建
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
          ref="myTable"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>

          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="EmployeeCode" label="员工编码" width="80" />
          <el-table-column prop="NameinEnglish" label="拼音姓名" width="80" />
          <el-table-column prop="ProjectName" label="项目部" width="90" />
          <el-table-column prop="WorkingUnit" label="用工单位" width="90" />
          <el-table-column prop="PassportCode" label="护照号" width="90" />
          <el-table-column
            prop="IQAMAID"
            label="暂住证号/海关入境号"
            width="150"
          />
          <el-table-column prop="BorderNumber" label="是否急件" width="90">
            <template slot-scope="scope">
              {{ scope.row.BorderNumber ? "Yes" : "No" }}
            </template>
          </el-table-column>

          <el-table-column prop="CreateDate" label="申请日期" width="90">
            <template slot-scope="scope">
              {{ scope.row.Main.CreateDate | tableDateFrom }}
            </template>
          </el-table-column>

          <el-table-column prop="AuditStatus" label="审批状态" width="90">
            <template slot-scope="scope">
              <div
                v-if="scope.row.Main.AuditStatus === null"
                class="label-primary approval"
              >
                待发布
              </div>
              <div
                v-if="scope.row.Main.AuditStatus === 0"
                class="label-primary approval"
              >
                待审核
              </div>
              <div
                v-if="scope.row.Main.AuditStatus === 1"
                class="label-success approval"
              >
                审核通过
              </div>
              <div
                v-if="scope.row.Main.AuditStatus === 2"
                class="label-info approval"
              >
                审核中
              </div>
              <div
                v-if="scope.row.Main.AuditStatus === 3"
                class="label-warning approval"
              >
                审核未通过
              </div>
              <div
                v-if="scope.row.Main.AuditStatus === 4"
                class="label-danger approval"
              >
                驳回
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <i class="fa fa-search" @click="query(scope.row)"></i>
              <i class="fa fa-edit" @click="edit(scope.row)"></i>
              <i
                class="fa fa-trash"
                @click="deleteTransferSponsorApplications(scope.row)"
              ></i>
              <i class="fa fa-print" @click="goUrl(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getTransferSponsorApplications2(1)"
          @current-change="getTransferSponsorApplications2"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="中东公司外事业务审批"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main">
        <ForeignAffairsBusiness
          ref="foreignAffairsBusiness"
          @update="getTransferSponsorApplications2"
          @close="handleClose"
        ></ForeignAffairsBusiness>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
        <el-button
          class="pop-save"
          :disabled="disabled || EditState == 2"
          @click="save"
          >保存</el-button
        >
        <el-button
          class="pop-release"
          :disabled="disabled || EditState == 2"
          @click="release"
          >发布</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getTransferSponsorApplications2,
  getTransferSponsorApplicationsItem,
  deleteTransferSponsorApplications,
  addTransferSponsorApplication,
  editTransferSponsorApplications,
} from "@/api/table";
import { getProjectAll, getCompanyAll } from "@/api/user";
import ForeignAffairsBusiness from "@/components/fTable/ForeignAffairsBusiness.vue";
export default {
  filters: {
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    leablDateFrom(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : "";
    },
  },
  data() {
    return {
      tableData: [],
      tableData2: [],
      multipleSelection: [],
      dialogVisible: false,
      options1: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "审核中",
        },
        {
          value: 3,
          label: "审核未通过",
        },
        {
          value: 4,
          label: "驳回",
        },
      ],
      page: 1,
      size: 10,
      total: 10,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      EditState: 1,
      disabled: false,
      dialogVisible2: false,
      name: null,
      loading: true,
      auditStatus: null,
      urgent: null,
      companyID: null,
      projectID: null,
      evacuationDate: null,
      ppCode: null,
      iqamaCode: null,
      borderNumber: null,
      projectList: [],
      companyList: [],
      options2: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
    };
  },
  components: { ForeignAffairsBusiness },
  methods: {
    handleClose() {
      this.$refs.foreignAffairsBusiness.clearData();
      setTimeout(() => {
        this.dialogVisible = false;
      }, 1);
      this.disabled = false;
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.name = null;
    },
    newBuilt() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.foreignAffairsBusiness.getNew(12);
      }, 100);
    },
    edit(data) {
      this.dialogVisible = true;
      this.EditState = data.Main.EditState;
      this.getTransferSponsorApplicationsItem(data.MainKeyID);
    },
    query(data) {
      this.dialogVisible = true;
      this.disabled = true;
      this.EditState = data.Main.EditState;
      this.getTransferSponsorApplicationsItem(data.MainKeyID);
    },

    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取表格
    getTransferSponsorApplications2(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      let startDate = null;
      let endDate = null;

      if (this.evacuationDate) {
        startDate = this.evacuationDate[0];
        endDate = this.evacuationDate[1];
      }

      const data = {
        page: this.page,
        size: this.size,
        auditStatus: this.auditStatus,
        urgent: this.urgent,
        companyID: this.companyID,
        projectID: this.projectID,
        ppCode: this.ppCode,
        iqamaCode: this.iqamaCode,
        borderNumber: this.borderNumber,
        startDate,
        endDate,
      };
      getTransferSponsorApplications2(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //保存
    save() {
      const data = this.$refs.foreignAffairsBusiness.addCostAllocation(1);
      if (!data.ID) {
        addTransferSponsorApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.getTransferSponsorApplications2();
            this.handleClose();
          }
        });
      } else {
        editTransferSponsorApplications(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getTransferSponsorApplications2();
            this.handleClose();
          }
        });
      }
    },
    //发布
    release() {
      const data = this.$refs.foreignAffairsBusiness.addCostAllocation(2);
      if (!data.ID) {
        addTransferSponsorApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.getTransferSponsorApplications2();
            this.handleClose();
          }
        });
      } else {
        editTransferSponsorApplications(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getTransferSponsorApplications2();
            this.handleClose();
          }
        });
      }
    },
    //获取详情
    getTransferSponsorApplicationsItem(key) {
      getTransferSponsorApplicationsItem({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.foreignAffairsBusiness.geteditData(res.response);
        }
      });
    },
    //删除
    deleteTransferSponsorApplications(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteTransferSponsorApplications({
          key: data.MainKeyID,
        }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getTransferSponsorApplications2();
          }
        });
      });
    },
    //前往打印
    goUrl(data) {
      this.$router.push(
        `/print?type=27&print=${this.powers.print}&export=${this.powers.export}&key=${data.MainKeyID}&name=${data.Main.ApplicationNumber}`
      );
    },
  },

  created() {
    this.getButtonPower();
    this.getTransferSponsorApplications2();

    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 70px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
